import useAuth from "../hooks/useAuth";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { ChangeEvent, useEffect, useState } from "react";
import { LocationState } from "../types/Common";
import { CustomAlert } from "../components/Alerts";
import { CustomGoogleLogin } from "../components/CustomGoogleLogin";
import { Password } from "../components/Password";
import { Divider } from "../components/Divider";
import { Button } from "../components/Button";
import { BoundForm } from "../components/BoundForm";
import { FieldInput } from "../components/FieldInput";
import { useForm } from "@opencraft/providence-redux/hooks";
import { TOKEN_PATH } from "../constants/api-urls";
import { Token } from "../types/User";
import { AxiosError } from "axios";
import { Trans, useTranslation } from "react-i18next";
import { FORGOT_PASSWORD, HOME_PAGE, REGISTER } from "../constants/urls";
import { GoogleOAuthProvider } from "@react-oauth/google";

interface LoginFormValues {
  username: string;
  password: string;
  client_id: string;
  client_secret: string;
  grant_type: string;
}

const Login = () => {
  const { saveSession } = useAuth();
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const { t } = useTranslation();
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    const username = localStorage.getItem("username");
    if (access_token && username) {
      navigate(locationState?.from?.pathname || HOME_PAGE, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formController = useForm<LoginFormValues>("login-form", {
    endpoint: TOKEN_PATH,
    fields: {
      username: {
        value: "",
        validators: [{ name: "email" }, { name: "required" }],
      },
      password: {
        value: "",
        validators: [{ name: "required" }],
      },
      client_id: { value: process.env.REACT_APP_CLIENT_ID! },
      client_secret: { value: process.env.REACT_APP_CLIENT_SECRET! },
      grant_type: { value: "password" },
    },
  });

  const setAlerts = (errors: string[], code?: string) => {
    formController.setErrors({
      messages: errors,
      status: code || "400",
      fields: {},
    });
  };

  const onSuccess = (resp: Token) => {
    saveSession(resp, rememberMe).then(() =>
      navigate(locationState?.from?.pathname || HOME_PAGE, { replace: true })
    );
  };

  const onFailure = (error: AxiosError) => {
    const response = (error.response?.data as any);
    if (response?.error === "invalid_grant") {
      setAlerts(["Your email or password is incorrect"], error.code);
    } else if (response?.detail) {
      setAlerts([response.detail], error.code);
    } else {
      formController.handleError(error);
    }
    formController.sending = false;
  };

  useEffect(() => {
    formController.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formController.f.username.model, formController.f.password.model]);

  return (
    <Container dir="auto">
      <Row className="row text-center justify-content-md-center">
        <Col lg={6} md={8} sm={10}>
          <h1 className="fw-bold">{t("login.form.title")}</h1>
          {googleClientId && (
            <GoogleOAuthProvider clientId={googleClientId}>
              <CustomGoogleLogin setErrors={setAlerts} />
            </GoogleOAuthProvider>
          )}
          <BoundForm
            preSend={() => null}
            controller={formController}
            success={onSuccess}
            failure={onFailure}
            className="text-start mb-5"
          >
            <FieldInput
              id="email"
              fielder={formController.f.username}
              name="email"
              type="email"
              label={t("login.form.email")}
              isInvalid={formController.errors.length > 0}
            />
            <Form.Group className="mb-4 mt-2">
              <Password
                isInvalid={formController.errors.length > 0}
                fielder={formController.f.password}
                label={t("login.form.password")}
              />
            </Form.Group>
            <Row>
              <Col xs={6}>
                <Form.Group className="mb-4 text-info">
                  <Form.Check
                    type="checkbox"
                    name="rememberMe"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setRememberMe(e.target.checked)
                    }
                    label={t("login.form.keepMeLoggedIn")}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} className="d-flex justify-content-end">
                <Form.Group className="ms-auto">
                  <Link to={FORGOT_PASSWORD} aria-label="Forgot password?">
                    {t("login.form.forgotPassword")}
                  </Link>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {formController.errors.map((error) => (
                <Col xs={12} key={error}>
                  <CustomAlert variant="danger">
                    <span className="align-middle">{error}</span>
                  </CustomAlert>
                </Col>
              ))}
            </Row>
            <Row>
              <Col className="d-flex justify-content-center mt-4">
                <Button
                  variant="primary"
                  type="submit"
                  loading={formController.sending}
                >
                  {t("login.form.submitBtn")}
                </Button>
              </Col>
            </Row>
          </BoundForm>
          <Divider />
          <Row className="mt-5">
            <p className="d-flex justify-content-center">
              <span className="me-2 text-info">
                <Trans
                  i18nKey="login.form.signUpCta"
                  components={[<Link to={REGISTER} />]}
                />
              </span>
            </p>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
