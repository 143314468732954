import { Link, useLocation, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useEffect } from "react";
import { AxiosError } from "axios";
import { CustomGoogleLogin } from "../components/CustomGoogleLogin";
import { Password } from "../components/Password";
import { Button } from "../components/Button";
import { Divider } from "../components/Divider";
import { useForm } from "@opencraft/providence-redux/hooks";
import { REGISTER_API } from "../constants/api-urls";
import { UserFormData } from "../types/User";
import { BoundForm } from "../components/BoundForm";
import { FieldInput } from "../components/FieldInput";
import { useTranslation } from "react-i18next";
import { EMAIL_VERIFICATION_SENT, HOME_PAGE, LOGIN } from "../constants/urls";
import { LocationState } from "../types/Common";
import { CustomAlert } from "../components/Alerts";
import { GoogleOAuthProvider } from "@react-oauth/google";

interface RegisterFormValues {
  email: string;
  client_id: string;
  client_secret: string;
  username: string;
  password: string;
}

const Register = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    const username = localStorage.getItem("username");
    if (access_token && username) {
      navigate(locationState?.from?.pathname || HOME_PAGE, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formController = useForm<RegisterFormValues>("register-form", {
    endpoint: REGISTER_API,
    fields: {
      email: {
        value: "",
        validators: [{ name: "email" }, { name: "required" }],
      },
      username: {
        value: "",
        validators: [{ name: "required" }],
      },
      password: {
        value: "",
        validators: [
          { name: "required" },
          { name: "length", args: { min: 8 } },
        ],
      },
      client_id: { value: process.env.REACT_APP_CLIENT_ID! },
      client_secret: { value: process.env.REACT_APP_CLIENT_SECRET! },
    },
  });

  const onSuccess = (resp: UserFormData) => {
    navigate(EMAIL_VERIFICATION_SENT, {
      replace: true,
      state: { id: resp.id, email: formController.f.email.model },
    });
  };

  const setAlerts = (errors: string[], code?: string) => {
    formController.setErrors({
      messages: errors,
      status: code || "400",
      fields: {},
    });
  };

  const onFailure = (error: AxiosError) => {
    const response = (error.response?.data as any);
    if (response?.non_field_errors?.length === 0) {
      delete response.non_field_errors;
    }
    if (response?.detail) {
      setAlerts([response.detail], error.code);
    } else {
      formController.handleError(error);
    }
    formController.sending = false;
  };

  useEffect(() => {
    formController.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formController.f.username.model,
    formController.f.email.model,
    formController.f.password.model,
  ]);

  return (
    <Container>
      <Row className="row text-center justify-content-md-center">
        <Col lg={6} md={8} sm={10}>
          <h1 className="fw-bold">{t("signup.form.title")}</h1>
          {googleClientId && (
            <GoogleOAuthProvider clientId={googleClientId}>
              <CustomGoogleLogin setErrors={setAlerts} />
            </GoogleOAuthProvider>
          )}
          <BoundForm
            preSend={() => null}
            controller={formController}
            success={onSuccess}
            failure={onFailure}
            className="text-start mb-4"
          >
            <FieldInput
              id="username"
              fielder={formController.f.username}
              name="username"
              type="text"
              label={t("signup.form.username")}
              className="mb-2"
            />
            <FieldInput
              id="email"
              fielder={formController.f.email}
              name="email"
              type="email"
              label={t("signup.form.email")}
            />
            <Form.Group className="mt-2">
              <Password
                label={t("signup.form.password")}
                showMeter
                fielder={formController.f.password}
              />
            </Form.Group>
            <Row className="mt-3">
              {formController.errors.map((error) => (
                <Col xs={12} key={error}>
                  <CustomAlert className="pb-0" variant="danger">
                    <p>{error}</p>
                  </CustomAlert>
                </Col>
              ))}
            </Row>
            <Row className="mt-2 pt-2">
              <Col className="d-flex justify-content-center mt-4">
                <Button
                  variant="primary"
                  type="submit"
                  loading={formController.sending}
                >
                  {t("signup.form.submitBtn")}
                </Button>
              </Col>
            </Row>
          </BoundForm>
          <Row className="mb-5">
            <small className="text-center text-info">
              {t("signup.form.termsNote")}
            </small>
          </Row>
          <Divider />
          <Row className="mt-5">
            <p className="d-flex justify-content-center">
              <span className="me-2 text-info">
                {t("signup.form.alreadyHasAccount")}
              </span>
              <Link to={LOGIN} aria-label="Login">
                {t("signup.form.loginBtn")}
              </Link>
            </p>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
