import axios, { AxiosInstance, AxiosRequestHeaders, AxiosHeaders } from "axios";
import { TOKEN_PATH, USER_PROFILE } from "../constants/api-urls";
import { getLanguageCode } from "../i18n";
import { UserSession, LoginMetaData } from "../types/User";

// https://docs.djangoproject.com/en/4.0/ref/csrf/#ajax
export function getCookie(name: string): string {
  let cookieValue = "";
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const refreshToken = async (): Promise<string> => {
  const refresh_token = localStorage.getItem("refresh_token");
  if (!refresh_token) {
    clearUserSession();
    return "";
  }
  console.log("access_token was invalid! refreshing access_token...");
  const loginData: LoginMetaData = {
    client_id: process.env.REACT_APP_CLIENT_ID!,
    client_secret: process.env.REACT_APP_CLIENT_SECRET!,
    grant_type: "refresh_token",
    refresh_token,
  };
  const csrftoken = getCookie("csrftoken");
  // @ts-ignore
  let headers: AxiosRequestHeaders = { "X-CSRFToken": csrftoken };
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}${TOKEN_PATH}`;
    const resp = await axios.post(url, loginData, { headers });
    clearUserSession();
    const userUrl = `${process.env.REACT_APP_API_BASE_URL}${USER_PROFILE}`;
    const userDetails = await axios.get(userUrl, {
      headers: getAuthHeaders(resp.data.access_token),
    });
    setUserSession({
      access_token: resp.data.access_token,
      refresh_token: resp.data.refresh_token,
      username: userDetails.data.username,
      id: userDetails.data.id,
    });
    return resp.data.access_token;
  } catch {
    clearUserSession();
    return "";
  }
};

export const createAxiosInstance = (): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });

  // to handle cases where multiple requests fail with expired access_token
  // here we create a variable that will store promise if we are already
  // refreshing the token. When we need a refresh token, besides calling Axios
  // request directly we just need check this variable. If this variable is
  // null, we will call function refreshToken and assign the promise to this
  // variable. If this variable isn’t null, we just need to wait for it to get
  // an access token.

  // store token request to check if we are already refreshing the token
  let refreshing_token: null | Promise<string>;

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const prevRequest = error?.config;
      if (error?.response?.status === 401 && !prevRequest?.sent) {
        prevRequest.sent = true;
        // create new refresh token promise or wait on already created promise
        refreshing_token = refreshing_token ? refreshing_token : refreshToken();
        const newAccessToken = await refreshing_token;
        refreshing_token = null;
        if (newAccessToken !== "") {
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axiosInstance(prevRequest);
        }
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export const getAuthHeaders = (
  access_token: string | null = null
): AxiosRequestHeaders => {
  const csrftoken = getCookie("csrftoken");
  const language: string = getLanguageCode();
  if (!access_token) {
    access_token = localStorage.getItem("access_token");
  }
  let headers = new AxiosHeaders({ "X-CSRFToken": csrftoken })
  
  if (access_token && access_token !== "") { 
    headers.setAuthorization("Bearer " + access_token)
    headers.setContentType("application/json")
    headers.setAccept("application/json")
    headers.set('Content-Language', language)
  }
  return headers;
};

export const setUserSession = ({
  access_token,
  refresh_token,
  username,
  id,
}: UserSession) => {
  if (access_token) {
    localStorage.setItem("access_token", access_token);
  }
  if (refresh_token) {
    localStorage.setItem("refresh_token", refresh_token);
  }
  if (username) {
    localStorage.setItem("username", username);
  }
  if (id) {
    localStorage.setItem("id", id.toString());
  }
};

export const clearUserSession = (keepUsername?: boolean) => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("id");
  if (!keepUsername) {
    localStorage.removeItem("username");
  }
};

/*Returns current url without query components*/
export const getCurrentUri = () => {
  return `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
};

export const parseToDate = (d: Date | string, skipTime?: boolean): Date => {
  let date: Date;
  if (d instanceof Date) {
    date = d;
    if (skipTime) {
      date.setHours(0, 0, 0, 0);
    }
  } else {
    date = new Date(skipTime ? d.substring(0, 10) : d);
  }

  return date;
};

export const dateString = (d?: Date | string, skipYear?: boolean) => {
  if (!d) {
    return "";
  }

  const date = parseToDate(d, true);

  let dateFormat: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
  };
  if (!skipYear) {
    dateFormat.year = "numeric";
  }

  return date.toLocaleString(getLanguageCode(), dateFormat);
};

export const isFirstOlderThanSecond = (
  first?: Date | string,
  second?: Date | string
): boolean => {
  if (!first || !second) {
    return false;
  }
  const fDate: Date = parseToDate(first);
  const sDate: Date = parseToDate(second);

  const subtract = fDate.getTime() - sDate.getTime();
  return subtract < 0;
};

export const downloadFile = (url: string, fileName: string) => {
  const axiosInstance = createAxiosInstance();
  const headers = getAuthHeaders();
  let blobUrl: string;
  axiosInstance
    .get(url, { responseType: "blob", headers })
    .then((response) => {
      blobUrl = window.URL.createObjectURL(new Blob([response.data]));
      let a = document.createElement("a");
      a.href = blobUrl;
      a.download = fileName;
      a.click();
    })
    .then(() => window.URL.revokeObjectURL(url));
};

// Generating random string: https://stackoverflow.com/a/27747377
// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
function dec2hex(dec: number) {
  return dec.toString(16).padStart(2, "0");
}

// generateId :: Integer -> String
export const generateRandomId = (len: number) => {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
};

/**
 * @param name - key for localStorage
 * @returns random string of length 30 from localStorage if present else creates one.
 */
export const getSavedToken = (name: string) => {
  let token = localStorage.getItem(name);
  if (token === null) {
    token = generateRandomId(30);
    localStorage.setItem(name, token);
  }
  return token;
};
