import { useTranslation } from "react-i18next";
import Nav from "react-bootstrap/Nav";
import BootstrapNavbar from "react-bootstrap/Navbar";
import { useEffect, useState } from "react";
import { SingleController } from "@opencraft/providence/base/singles/types/SingleController";
import { ChecklistListName } from "../types/Task";

export declare type TabCountState = {
  [key in ChecklistListName]: number;
};

export const parseChecklistListNameFromString = (
  str: string,
  isArchived?: boolean,
): ChecklistListName => {
  if (isArchived) {
    return ChecklistListName.ALL
  }
  let listName: ChecklistListName = str as ChecklistListName;
  if (Object.values(ChecklistListName).includes(listName)) {
    return listName;
  }
  return ChecklistListName.TO_DO;
};

declare interface ChecklistsTabsArgs {
  tabCountController: SingleController<{
    active: TabCountState;
    archived: TabCountState;
  }>;
  isArchived?: boolean;
  onChange: (listName: ChecklistListName) => void;
  defaultListName?: ChecklistListName;
}

export const ChecklistsTabs = ({
  isArchived,
  tabCountController,
  onChange,
  defaultListName,
}: ChecklistsTabsArgs) => {
  const { t } = useTranslation();
  const [currentListName, setCurrentListName] = useState<ChecklistListName>(
    defaultListName || ChecklistListName.TO_DO
  );
  const [currentCountMap, setCurrentCountMap] = useState<TabCountState>({
    TO_DO: 0,
    ASSIGNED_TO_ME: 0,
    ALL: 0,
  });
  useEffect(() => {
    onChange(currentListName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentListName]);
  useEffect(() => {
    const countMap = isArchived
      ? tabCountController.x!.archived
      : tabCountController.x!.active;
    setCurrentCountMap(countMap);
  }, [isArchived, tabCountController.x]);
  return (
    <BootstrapNavbar className="tabs checklist-tabs" variant="dark">
      <Nav className="me-auto">
        <Nav.Link
          onClick={() => setCurrentListName(ChecklistListName.TO_DO)}
          className="link ps-0 pe-3 me-4"
          active={currentListName === ChecklistListName.TO_DO}
        >
          {(!!currentCountMap[ChecklistListName.TO_DO] ||
            currentCountMap[ChecklistListName.TO_DO] === 0) &&
            t("checklists.tab.toDo", {
              count: currentCountMap[ChecklistListName.TO_DO],
            })}
        </Nav.Link>
        <Nav.Link
          onClick={() => setCurrentListName(ChecklistListName.ASSIGNED_TO_ME)}
          className="link pe-3 me-4"
          active={currentListName === ChecklistListName.ASSIGNED_TO_ME}
        >
          {(!!currentCountMap[ChecklistListName.ASSIGNED_TO_ME] ||
            currentCountMap[ChecklistListName.ASSIGNED_TO_ME] === 0) &&
            t("checklists.tab.assignedToMe", {
              count: currentCountMap[ChecklistListName.ASSIGNED_TO_ME],
            })}
        </Nav.Link>
        <Nav.Link
          onClick={() => setCurrentListName(ChecklistListName.ALL)}
          className="link"
          active={currentListName === ChecklistListName.ALL}
        >
          {(!!currentCountMap[ChecklistListName.ALL] ||
            currentCountMap[ChecklistListName.ALL] === 0) &&
            t("checklists.tab.all", {
              count: currentCountMap[ChecklistListName.ALL],
            })}
        </Nav.Link>
      </Nav>
    </BootstrapNavbar>
  );
};
