import { TaskListReview } from "../types/Task";
import { Paginated } from "./Paginated";
import Container from "react-bootstrap/Container";
import { ReactComponent as ArchiveIcon } from "../assets/icons/archive.svg";
import { ReactComponent as UnarchiveIcon } from "../assets/icons/unarchive.svg";
import { Col, Form, InputGroup, Nav, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ListController } from "@opencraft/providence/base/lists/types/ListController";
import { useEffect, useState } from "react";
import { ChecklistTableRow } from "./ChecklistTableRow";
import {Single} from "./Single";

declare interface ChecklistsTableArgs {
  controller: ListController<TaskListReview>;
  isArchived?: boolean;
  currentUsername: string;
  onSingleArchiveUnarchive: (id: string, value: boolean) => void;
  onBulkArchiveUnarchive: (ids: string[], value: boolean) => void;
}

export const ChecklistsTable = ({
  controller,
  isArchived,
  currentUsername,
  onSingleArchiveUnarchive,
  onBulkArchiveUnarchive,
}: ChecklistsTableArgs) => {
  const { t } = useTranslation();
  const [selectedChecklist, setSelectedChecklist] = useState(new Set<string>());
  const archivedHeadingColSize = {
    xs: [12, 0, 0, 0, 0, 0],
    sm: [8, 0, 0, 0, 0, 4],
    md: [3, 3, 0, 0, 0, 4],
    lg: [3, 3, 2, 2, 0, 2],
  };
  const activeHeadingColSize = {
    xs: [7, 0, 0, 0, 5, 0],
    sm: [5, 0, 0, 0, 3, 4],
    md: [3, 3, 0, 0, 3, 3],
    lg: [2, 2, 2, 2, 2, 2],
  };
  const headingColSizes = isArchived
    ? archivedHeadingColSize
    : activeHeadingColSize;
  const archivedColSizes = {
    xs: [8, 0, 0, 0, 0, 4],
    sm: [8, 0, 0, 0, 0, 4],
    md: [3, 3, 0, 0, 0, 6],
    lg: [3, 3, 2, 2, 0, 2],
  };
  const activeColSizes = {
    xs: [7, 0, 0, 0, 3, 2],
    sm: [5, 0, 0, 0, 3, 4],
    md: [3, 3, 0, 0, 3, 3],
    lg: [2, 2, 2, 2, 2, 2],
  };
  const colSizes = isArchived ? archivedColSizes : activeColSizes;
  const isChecklistSelected = (checklistId: string) =>
    selectedChecklist.has(checklistId);
  const setChecklistSelectState = (checklistId: string, isChecked: boolean) => {
    if (isChecklistSelected(checklistId) === isChecked) {
      return;
    }
    if (isChecked) {
      selectedChecklist.add(checklistId);
    } else {
      selectedChecklist.delete(checklistId);
    }
    setSelectedChecklist(new Set(selectedChecklist));
  };

  const selectAll = () => {
    controller.list.forEach((singleController) => {
      setChecklistSelectState(singleController.x!.id, true);
    });
  };

  const deselectAll = () => {
    controller.list.forEach((singleController) => {
      setChecklistSelectState(singleController.x!.id, false);
    });
  };
  const operateChangingIsArchive = () => {
    const ids: string[] = Array.from(selectedChecklist).slice();
    onBulkArchiveUnarchive(ids, !isArchived);
  };

  /** Hooks */
  useEffect(() => {
    const selected = Array.from(selectedChecklist).slice();
    for (const id of selected) {
      if (
        controller.list.filter((single) => single.x!.id === id).length === 0
      ) {
        selectedChecklist.delete(id);
      }
    }
    setSelectedChecklist(selectedChecklist);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller.list]);
  return (
    <Paginated hideTop controller={controller}>
      <Container className="checklist-table">
        <Row className="position-sticky top-n1 me-0 ms-0 py-4 fs-sm heading z-index-sticky">
          <Col
            xs={headingColSizes.xs[0]}
            sm={headingColSizes.sm[0]}
            md={headingColSizes.md[0]}
            lg={headingColSizes.lg[0]}
          >
            <InputGroup
              className={`text-sm switchable mt-n2 ${
                selectedChecklist.size > 0 ? "" : "not-checked"
              }`}
            >
              <InputGroup.Text
                className={`with-border py-2 start ${
                  selectedChecklist.size > 0 ? "px-2" : "me-3 px-0"
                }`}
              >
                <Form.Check.Input
                  className="minus sm cursor-pointer"
                  disabled={controller.list.length === 0}
                  type="checkbox"
                  checked={selectedChecklist.size > 0}
                  onChange={() =>
                    selectedChecklist.size === 0 ? selectAll() : deselectAll()
                  }
                />
              </InputGroup.Text>
              {selectedChecklist.size > 0 ? (
                <InputGroup.Text
                  disabled={selectedChecklist.size === 0}
                  as={Nav.Link}
                  onClick={() => operateChangingIsArchive()}
                  className="d-flex px-1 py-0 white with-border"
                >
                  {isArchived ? (
                    <UnarchiveIcon className="base-icon sm me-2 path white" />
                  ) : (
                    <ArchiveIcon className="base-icon sm me-2 path white" />
                  )}
                  <small>
                    {isArchived
                      ? t("checklists.action.unarchive")
                      : t("checklists.action.archive")}
                  </small>
                </InputGroup.Text>
              ) : (
                <InputGroup.Text
                  as={Nav.Link}
                  onClick={() => selectAll()}
                  className="px-0 me-3 text-reset fs-sm"
                >
                  {t("checklists.table.heading.name")}
                </InputGroup.Text>
              )}
            </InputGroup>
          </Col>
          <Col
            className="d-none d-md-block"
            xs={headingColSizes.xs[1]}
            sm={headingColSizes.sm[1]}
            md={headingColSizes.md[1]}
            lg={headingColSizes.lg[1]}
          >
            {t("checklists.table.heading.assignee")}
          </Col>
          <Col
            className="d-none d-lg-block"
            xs={headingColSizes.xs[2]}
            sm={headingColSizes.sm[2]}
            md={headingColSizes.md[2]}
            lg={headingColSizes.lg[2]}
          >
            {t("checklists.table.heading.progress")}
          </Col>
          <Col
            className="d-none d-lg-block"
            xs={headingColSizes.xs[3]}
            sm={headingColSizes.sm[3]}
            md={headingColSizes.md[3]}
            lg={headingColSizes.lg[3]}
          >
            {t("checklists.table.heading.repeat")}
          </Col>
          {!isArchived && (
            <Col
              xs={headingColSizes.xs[4]}
              sm={headingColSizes.sm[4]}
              md={headingColSizes.md[4]}
              lg={headingColSizes.lg[4]}
            >
              {t("checklists.table.heading.status")}
            </Col>
          )}
          <Col
            className="d-none d-sm-block"
            xs={headingColSizes.xs[5]}
            sm={headingColSizes.sm[5]}
            md={headingColSizes.md[5]}
            lg={headingColSizes.lg[5]}
          >
            {t("checklists.table.heading.due")}
          </Col>
        </Row>
        {controller.list.length > 0 ? (
          controller.list.map((singleController) => (
            <Single key={singleController.x!.id} controller={singleController}>
              {() => (
                <ChecklistTableRow
                  singleController={singleController}
                  isChecklistSelected={isChecklistSelected}
                  setChecklistSelectState={setChecklistSelectState}
                  isArchived={!!isArchived}
                  currentUsername={currentUsername}
                  onSingleArchiveUnarchive={onSingleArchiveUnarchive}
                  colSizes={colSizes}
                />
              )}
            </Single>
          ))
        ) : (
          <Row className="me-0 ms-0 fs-sm">
            <Col className="text-center">
              {t("checklists.message.noChecklist")}
            </Col>
          </Row>
        )}
      </Container>
    </Paginated>
  );
};
