import { SingleController } from "@opencraft/providence/base/singles/types/SingleController";
import { useList, useSingle } from "@opencraft/providence-redux/hooks";
import { Run, Task, TaskList } from "../types/Task";
import { Paginated } from "../components/Paginated";
import { TaskItem } from "../components/TaskItem";
import { Subsection } from "../components/Subsection";
import { WORKFLOW_API } from "../constants/api-urls";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useEffect, useLayoutEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { Body } from "../components/Body";
import { Title } from "../components/Title";
import { CustomAlert } from "../components/Alerts";
import { LoadSection } from "../components/LoadSection";
import { ProgressState } from "../components/ChecklistProgress";
import useAuth from "../hooks/useAuth";
import { Trans, useTranslation } from "react-i18next";
import { dateString } from "../utils/helpers";
import { ConfettiState } from "../components/Confetti";
import {Single} from "../components/Single";
import {useSelector} from "react-redux";

type ChecklistDetailRouteParams = {
  checklistId: string;
};

declare interface TaskWithSubtasksItem {
  taskController: SingleController<Task>;
  subtasks: SingleController<Task>[];
}

declare interface TaskMap {
  [key: string]: SingleController<Task>[]
}

declare interface ChecklistState {
  totalTasks: number;
  completedTasks: number;
  requiredCompletedTasks: number;
  requiredTotalTasks: number;
  incompleteRequiredTasks: SingleController<Task>[];
  tasksWithInteraction: string[];
  failed: boolean;
  taskMap: TaskMap,
  rootTasks: SingleController<Task>[]
}

const ensureMapId = (taskMap: TaskMap, id: string) => {
  if (!taskMap[id]) {
    taskMap[id] = []
  }
}

const performTallies = (stats: ChecklistState, tasks: SingleController<Task>[]) => {
  tasks.forEach((task: SingleController<Task>) => {
    ensureMapId(stats.taskMap, task.x.id)
    if (task.x.parent) {
      ensureMapId(stats.taskMap, task.x.parent)
      stats.taskMap[task.x.parent].push(task)
    } else {
      stats.rootTasks.push(task)
    }
    if (task.x.interface_type !== "checkbox") {
      stats.tasksWithInteraction.push(task.x.id)
    }
    updateChecklistStatePerTask(stats, task)
  })
}

function updateChecklistStatePerTask(
  stats: ChecklistState,
  task: SingleController<Task>,
) {
  stats.totalTasks += 1;
  if (task.x.required) {
    stats.requiredTotalTasks += 1;
    if (task.x.completed) {
      stats.requiredCompletedTasks += 1;
    } else {
      stats.incompleteRequiredTasks.push(task)
    }
    if (task.p.response.errors.length && !stats.failed) {
      stats.failed = true;
    }
  }
  if (task.x.completed) {
    stats.completedTasks += 1;
  }
}

const TaskWithSubtasks = ({
  taskController,
  subtasks,
}: TaskWithSubtasksItem) => {

  const taskIsSubsection = taskController.x!.interface_type === "subsection";

  if (!taskIsSubsection) {
    return <TaskItem controller={taskController} />;
  }

  const subsectionComplete =
    (taskController.x!.required
      ? subtasks
          .filter((sc) => sc.x!.required)
          .filter((sc) => !sc.x!.completed).length
      : subtasks.filter((sc) => !sc.x!.completed).length) === 0;

  return (
    <Subsection controller={taskController} isCompleted={subsectionComplete}>
      {subtasks.map((cc) => (
        <Single key={cc.x!.id} controller={cc}>
          {() => <TaskItem controller={cc} />}
        </Single>
      ))}
    </Subsection>
  );
};

const emptyChecklistStats: () => ChecklistState = () => ({
  totalTasks: 0,
  completedTasks: 0,
  incompleteRequiredTasks: [],
  requiredCompletedTasks: 0,
  requiredTotalTasks: 0,
  tasksWithInteraction: [],
  failed: false,
  taskMap: {},
  rootTasks: [],
});

export const RunInfo = ({ run_info }: { run_info: Run }) => {
  const { t } = useTranslation();
  const skipYear =
    new Date(run_info.start_date).getFullYear() ===
    new Date(run_info.end_date).getFullYear();
  const start_date = dateString(run_info?.start_date, skipYear);
  const end_date = dateString(run_info?.end_date);
  const due_date = dateString(run_info?.due_date);
  const emptySpace = "\u00A0";
  return (
    <div dir="auto">
      <p className="mb-0">
        <b>{t("checklistDetail.team")} </b>
        {run_info.team_name}
      </p>
      <p>
        <small>
          {due_date === end_date
            ? t("checklistDetail.runDatesWithoutDueDate", {
              start_date,
              end_date,
            })
            : t("checklistDetail.runDatesWithDueDate", {
              start_date,
              end_date,
              due_date,
              emptySpace,
            })}
        </small>
      </p>
    </div>
  );
};

declare interface ForceSubmitAlertProps {
  setShowForceSubmitAlert: (val: boolean) => void,
  submitChecklist: (val: boolean) => void,
  showForceSubmitAlert: boolean,
}

const ForceSubmitAlert = ({setShowForceSubmitAlert, submitChecklist, showForceSubmitAlert}: ForceSubmitAlertProps) => {
  const { t } = useTranslation();
  const alertTitle = t("checklistDetail.form.forceSaveAlertTitle");
  const alertBody = t("checklistDetail.form.forceSaveAlertBody");
  const leftButton = (
    <Button
      onClick={() => setShowForceSubmitAlert(false)}
      variant="outline-dark"
      className="me-3 fs-8 px-3 py-1"
    >
      {t("checklistDetail.form.keepEditingBtn")}
    </Button>
  );
  const rightButton = (
    <Button
      onClick={() => submitChecklist(true)}
      variant="dark"
      className="fs-8 px-3 py-1"
    >
      {t("checklistDetail.form.forceSaveSubmitBtn")}
    </Button>
  );
  return (
    <Row>
      <Col>
        <CustomAlert
          className="mt-5"
          variant="warning"
          show={showForceSubmitAlert}
          alertTitle={alertTitle}
          alertBody={alertBody}
          showButtons={true}
          leftButton={leftButton}
          rightButton={rightButton}
        ></CustomAlert>
      </Col>
    </Row>
  );
};

export const ChecklistDetail = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const username = auth?.username!;
  const { checklistId } = useParams<ChecklistDetailRouteParams>();
  const checklistUrl = `${WORKFLOW_API}/user/${username}/checklist`;
  const [showForceSubmitAlert, setShowForceSubmitAlert] = useState(false);

  // checklist details
  const checklistController = useSingle<TaskList>([username, checklistId!], {
    endpoint: `${checklistUrl}/${checklistId}/`,
  });

  // track submission count to update submit button text
  const submissionCount = useSingle<{ num: number }>(
    [checklistId!, "submissionCount"],
    {
      endpoint: "#",
      x: { num: 0 },
    }
  );

  /// Read only mode, used for user in the same team to see coworkers checklists
  const readOnly = useSingle<{ value: boolean }>("onlyRead", {
    endpoint: "#",
    x: { value: true },
  });

  useEffect(() => {
    checklistController.get().then(() => {
      // Check if the logged user is the assignee to disable the only read mode
      if (checklistController.x?.assignee.username === auth?.username) {
        readOnly.p.value.model = false;
      }
      // update checklistController on page number change to handle hidden completed tasks
      if (checklistController.x?.completed) {
        submissionCount.p.num.model = 1;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // tasks list
  const tasksApiEndpoint = `${checklistUrl}/${checklistId}/task/`;
  const tasksController = useList<Task>([checklistId!, "task"], {
    endpoint: tasksApiEndpoint,
    paginated: false,
  });

  tasksController.getOnce()

  // progress bar controller
  const progressValue = useSingle<ProgressState>("taskProgress", {
    endpoint: "#",
  });
  // confetti controller
  const confettiState = useSingle<ConfettiState>("confettiState", {
    endpoint: "#",
  });

  useSelector(() => {
    // Subscribe to the specific changes in the subtasks we're interested in for the purpose of rerendering this
    // parent component. Namely, the ones tracking progress/completion.
    return JSON.stringify(tasksController.list.map((item) => [item.x.id, item.x.completed]))
  })

  const checklistState = emptyChecklistStats();
  performTallies(checklistState, tasksController.list)

  const progressPercent = (checklistState.requiredCompletedTasks / checklistState.requiredTotalTasks) * 100

  useEffect(() => {
    // Doing this in useEffect because React is displeased if we mutate state critical to components elsewhere
    // within the render function itself.
    progressValue.p.val.model = progressPercent
  }, [progressPercent, progressValue.p.val]);

  useLayoutEffect(() => {
    if (checklistController.p.completed.model || !showForceSubmitAlert) {
      return
    }
    const target = document.querySelector('.required-missing')
    if (target) {
      target.scrollIntoView({behavior: 'smooth', block: 'center'})
    }
  }, [showForceSubmitAlert, checklistController.p.completed.model]);

  const pendingTasks = checklistState.incompleteRequiredTasks.length;
  if (!pendingTasks && showForceSubmitAlert) {
    setShowForceSubmitAlert(false)
  }

  const items_text =
    pendingTasks === 1
      ? t("checklistDetail.itemSingular")
      : t("checklistDetail.itemPlural");

  const submitChecklist = (force = false) => {
    if (pendingTasks > 0 && !force) {
      checklistState.incompleteRequiredTasks.forEach(
        (task) => {task.p.completed.errors = [t("checklistDetail.form.pleaseComplete")]}
      )
      setShowForceSubmitAlert(true);
      return;
    }
    checklistController.p.completed.errors = [];
    checklistController
      .patch({ completed: true, force_complete: force })
      .then((x) => {
        if (x.completed) {
          submissionCount.p.num.model += 1;
          confettiState.p.fire.model = Math.random();
        }
        checklistController.setX(x);
      })
      .catch((err) => {
        checklistController.p.completed.errors = err.response.data.completed;
        checklistController.p.completed.model = false;
      })
      .finally(() => {
        setShowForceSubmitAlert(false);
      });
  };

  return (
    <LoadSection controllers={[checklistController]}>
      {() => (
        <Container>
          <Row>
            <Col dir="auto">
              <h1 className="fw-bold">
                <Title text={checklistController.x!.name} />
              </h1>
              {checklistController.x?.run && (
                <RunInfo run_info={checklistController.x?.run} />
              )}
              <Body text={checklistController.x!.body} />
            </Col>
          </Row>
          <Row>
            <small className="text-muted mb-4">
              {t("checklistDetail.form.completeOnTotal", {
                completedTasks: checklistState.completedTasks,
                totalTasks: checklistState.totalTasks,
              })}
            </small>
          </Row>
          <Paginated controller={tasksController}>
            <Accordion
              className="px-0 mx-3 mb-3 border-bottom border-2 border-light-navy"
              defaultActiveKey={checklistState.tasksWithInteraction}
              alwaysOpen
              flush
            >
              {checklistState.rootTasks.map((tc) => (
                <Single key={tc.x!.id} controller={tc}>
                  {() => (
                    <>
                      {tc.p.response.errors?.map((error) => (
                        <Row key={error} className="mt-3">
                          <Col>
                            <CustomAlert className="pb-0 d-flex" variant="danger">
                              <p>{error}</p>
                            </CustomAlert>
                          </Col>
                        </Row>
                      ))}
                      <TaskWithSubtasks
                        taskController={tc}
                        subtasks={checklistState.taskMap[tc.x.id]}
                      />
                    </>
                  )}
                </Single>
              ))}
            </Accordion>
          </Paginated>
          {!checklistController.p.completed.errors?.length &&
            checklistController.p.completed.model && (
              <Row className="mt-3">
                <Col>
                  <CustomAlert className="pb-0 d-flex" variant="success">
                    <p>{t("checklistDetail.form.checklistCompleted")}</p>
                  </CustomAlert>
                </Col>
              </Row>
            )}
          {checklistController.p.completed.errors?.map((error) => (
            <Row key={error} className="mt-3">
              <Col>
                <CustomAlert className="pb-0 d-flex" variant="danger">
                  <p>{error}</p>
                </CustomAlert>
              </Col>
            </Row>
          ))}
          {!readOnly.x!.value && (
            <>
              <ForceSubmitAlert
                setShowForceSubmitAlert={setShowForceSubmitAlert}
                showForceSubmitAlert={showForceSubmitAlert}
                submitChecklist={submitChecklist}
              />
              {!showForceSubmitAlert &&
                (!checklistController.p.completed.model ||
                  checklistController.p.completed.errors?.length !== 0) && (
                  <div>
                    <Row className="mt-2 pt-2">
                      <p>
                        <Trans i18nKey="checklistDetail.form.autoSaveNotice" />
                      </p>
                    </Row>
                    <Row className="mt-3">
                      <Col md={6}>
                        <Button
                          variant="primary"
                          onClick={() => submitChecklist()}
                          disabled={checklistState.failed}
                        >
                          {submissionCount.p.num.model > 0
                            ? t("checklistDetail.form.submitBtnAgain")
                            : t("checklistDetail.form.submitBtn")}
                        </Button>
                      </Col>
                    </Row>
                    {pendingTasks > 0 && (
                      <Row className="mt-2 pt-2">
                        <p className="fs-8">
                          {t("checklistDetail.form.pendingTasks", {
                            pendingTasks,
                            itemsText: items_text,
                          })}
                        </p>
                      </Row>
                    )}
                  </div>
                )}
            </>
          )}
        </Container>
      )}
    </LoadSection>
  );
};
