import { useList } from "@opencraft/providence-redux/hooks";
import { Paginated } from "../components/Paginated";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Definition } from "../types/Definition";
import {
  ONE_OFFS_LIST_API,
  USER_TEAM_NAMES,
  WORKFLOW_API,
} from "../constants/api-urls";
import { Body } from "../components/Body";
import { Title } from "../components/Title";
import { TaskList } from "../types/Task";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { USER_CHECKLISTS } from "../constants/urls";
import { TeamNames } from "../types/User";
import SingleSelectDropdown from "../components/SingleSelectDropdown";
import { useState } from "react";
import { SingleValue } from "react-select";
import {Single} from "../components/Single";

declare interface TemplateCardBase {
  id: string;
  name: string;
  body: string;
  totalTasks: number;
  team: string;
  username: string;
  userTeamsController: any;
}

const TemplateCard = ({
  id,
  name,
  body,
  totalTasks,
  team,
  username,
  userTeamsController,
}: TemplateCardBase) => {
  const { t } = useTranslation();
  const userChecklist = useList<TaskList>("userChecklist", {
    endpoint: `${WORKFLOW_API}/user/${username}/checklist/`,
  });
  const navigate = useNavigate();
  const showTeamsDropdown = team === null || team === undefined || team === "";
  const [selectedTeam, setSelectedTeam] = useState("");
  const onTeamChange = (
    newValue: SingleValue<{ value?: string; label?: string }>
  ) => {
    setSelectedTeam(newValue?.value ?? "");
  };

  const handleClick = async () => {
    const resp: { data: { id: string } } = await userChecklist.post({
      definition: id,
      team: selectedTeam !== "" ? selectedTeam : team,
    });
    const checklistId: string = resp.data!.id;
    navigate(`${USER_CHECKLISTS}/${checklistId}`);
  };

  return (
    <Card dir="auto">
      <Card.Body>
        <Card.Title>
          <Title text={name} />
        </Card.Title>
        <Card.Subtitle className="mb-4 text-muted">
          {t("templateList.taskCount", { count: totalTasks })}
        </Card.Subtitle>
        <Card.Text>
          <Body text={body} />
        </Card.Text>
        {showTeamsDropdown && (
          <>
            <Card.Text>
              <Body text={t("templateList.selectTeamLabel")} />
            </Card.Text>
            <SingleSelectDropdown
              name="teamplate-list-team-selection"
              onChange={onTeamChange}
              controller={userTeamsController}
              selectedValue={selectedTeam}
            />
          </>
        )}
        <Button
          className="mt-3"
          size="sm"
          variant="primary"
          onClick={handleClick}
        >
          {t("templateList.useTemplate")}
        </Button>
      </Card.Body>
    </Card>
  );
};

export const TemplateList = () => {
  const { auth } = useAuth();
  const username = auth?.username!;
  const controller = useList<Definition>("definitions", {
    endpoint: ONE_OFFS_LIST_API,
  });
  const teamsController = useList<TeamNames>("teamsController", {
    endpoint: USER_TEAM_NAMES,
    paginated: false,
  });
  controller.getOnce();
  teamsController.getOnce();

  const totalDefinitions = controller.count;

  return (
    <Container>
      <Row>
        <Col>
          <h1 className="fw-bold">{t("templateList.title")}</h1>
          {totalDefinitions && totalDefinitions > 0 ? (
            <p>{t("templateList.useTemplateDescription")}</p>
          ) : (
            <p>{t("templateList.noTemplate")}</p>
          )}
        </Col>
      </Row>
      <Row>
        {totalDefinitions && totalDefinitions > 0 && (
          <small className="text-muted mb-4">
            {t("templateList.templateDefinitionCount", {
              count: totalDefinitions,
            })}
          </small>
        )}
      </Row>
      <Paginated controller={controller}>
        {controller.list.map((singleController) => (
          <Single controller={singleController} key={singleController.x!.id}>
            {() => (
              <Col md={6} lg={4} className="mb-4">
                <TemplateCard
                  id={singleController.x!.id}
                  name={singleController.x!.name}
                  body={singleController.x!.body}
                  totalTasks={singleController.x!.task_definitions.length}
                  team={singleController.x!.team}
                  username={username}
                  userTeamsController={teamsController}
                />
              </Col>
            )}
          </Single>
        ))}
      </Paginated>
    </Container>
  );
};
