import { Dropdown, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { ReactComponent as ThreeDotMenuIcon } from "../assets/icons/three-dot-menu.svg";
import { ReactComponent as EditIcon } from "../assets/icons/edit.svg";
import { ReactComponent as ArchiveIcon } from "../assets/icons/archive.svg";
import { ReactComponent as UnarchiveIcon } from "../assets/icons/unarchive.svg";
import { ReactComponent as ShareListIcon } from "../assets/icons/share-list.svg";
import { ReactComponent as ListIcon } from "../assets/icons/list.svg";
import { TaskListReview } from "../types/Task";
import useNotification from "../hooks/useNotification";
import { useTranslation } from "react-i18next";
import { SingleController } from "@opencraft/providence/base/singles/types/SingleController";

export declare interface ChecklistActionArgs {
  onArchiveUnarchive: (id: string, isArchived: boolean) => void;
  controller: SingleController<TaskListReview>;
  isOwnedByCurrentUser: boolean;
  taskReportLink: string;
}

export const ChecklistAction = ({
  controller,
  isOwnedByCurrentUser,
  onArchiveUnarchive,
  taskReportLink,
}: ChecklistActionArgs) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setNotification({
      message: t("checklists.notification.copiedToClipboard"),
      variant: "success",
    });
  };
  return isOwnedByCurrentUser ? (
    <Dropdown>
      <Dropdown.Toggle
        className="px-2 py-0 circle-active ms-auto interaction"
        as={Nav.Link}
        to="#"
        split
        id="dropdown-split-basic"
      >
        <ThreeDotMenuIcon className="base-icon" />
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="p-2 border fs-sm"
      >
        {!controller.x!.is_archived && (
          <Dropdown.Item
            className="text-reset"
            as={NavLink}
            to={`/lists/${controller.x!.id}/`}
          >
            <EditIcon className="base-icon sm me-2" />
            <span className="mb-0">{t("checklists.action.view")}</span>
          </Dropdown.Item>
        )}
        {!controller.x!.is_archived && (
          <Dropdown.Item
            className="text-reset"
            onClick={() =>
              copyToClipboard(
                `${window.location.origin}/lists/${controller.x!.id}/`
              )
            }
          >
            <ShareListIcon className="base-icon sm me-2" />
            <span className="mb-0">{t("checklists.action.copyLink")}</span>
          </Dropdown.Item>
        )}
        {taskReportLink !== "" && (
          <Dropdown.Item
            as={NavLink}
            className="text-reset"
            to={taskReportLink}
          >
            <ListIcon className="base-icon sm me-2" />
            <span className="mb-0">{t("checklists.action.showReport")}</span>
          </Dropdown.Item>
        )}
        <Dropdown.Item
          className="text-reset"
          onClick={() =>
            onArchiveUnarchive(controller.x!.id, !controller.x!.is_archived)
          }
        >
          {controller.x!.is_archived ? (
            <UnarchiveIcon className="base-icon sm me-2" />
          ) : (
            <ArchiveIcon className="base-icon sm me-2" />
          )}
          <span className="mb-0">
            {controller.x!.is_archived
              ? t("checklists.action.unarchive")
              : t("checklists.action.archive")}
          </span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <></>
  );
};
